import { EMPTY } from 'rxjs';
import { createActionGroup, props } from '@ngrx/store';
import { SalesSumaryModel, SumaryPerDayModel } from '../../_models/sales_sumary.model';

export const SalesSumaryActions = createActionGroup({
    source: 'salesSumary',
    events: {
        'Load sales sumary': props<{ filters: {} }>(),
        'Load sales sumary Success': props<{ data: Readonly<SalesSumaryModel> }>(),
        'Load sales sumary Per Day': props<{ filters: {} }>(),
        'Load sales sumary Per Day Success': props<{ items: ReadonlyArray<SumaryPerDayModel>, total: number }>(),
        'Download sales sumary': props<{ filters: {} }>(),
    },
});