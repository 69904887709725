import { BaseModel } from "./base.model";

export class RankingModel {
    salesman_id: string;
    salesman_name: string;
    total_units: string;
    ranking: string;
    total_price: string;
    total_commission: string;
    points_earned: string;
    prize: RankingPrizeModel;


    constructor(obj: any = null) {
        this.salesman_id = obj?.salesman_id
        this.salesman_name = obj?.salesman_name
        this.total_units = obj?.total_units
        this.ranking = obj?.ranking
        this.total_price = obj?.total_price
        this.total_commission = obj?.total_commission
        this.points_earned = obj?.points_earned
        this.prize = new RankingPrizeModel(obj?.prize)
    }
}

export class RankingPrizeModel extends BaseModel {
    name: string;
    description: string;
    required_points: number;


    constructor(obj: any = null) {
        super(obj);
        this.id = obj?.id;
        this.name = obj?.name;
        this.description = obj?.description;
        this.required_points = obj?.required_points;
    }
}
