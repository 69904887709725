import { createSelector, createFeatureSelector } from '@ngrx/store';

import { SalesSumaryState } from '../_reducers/sales-sumary.reducer';
import { SalesSumaryModel } from '../../_models/sales_sumary.model';
import { SalesByServiceState } from '../_reducers/sales-by-service.reducer';
import { SalesByServiceModel } from '../../_models/sales_by_service.model';

export const selectSalesByServiceState = createFeatureSelector<SalesByServiceState>('salesByService');


export const currentSalesByService = createSelector(
    selectSalesByServiceState,
    (salesByService: SalesByServiceState) => salesByService.list?.data?.map((sale) => new SalesByServiceModel(sale))
);


export const currentSalesByServiceFilters = createSelector(
    selectSalesByServiceState,
    (salesByService: SalesByServiceState) => salesByService.list.filters
);
