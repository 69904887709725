export class SalesByServiceModel {
    service_id: string;
    service_name: string
    total_units: number;
    total_price: number;
    total_commission: number;
    points_earned: number;


    constructor(obj: any = null) {
        this.service_id = obj?.service_id
        this.service_name = obj?.service_name
        this.total_commission = obj?.total_commission;
        this.total_price = obj?.total_price;
        this.total_units = obj?.total_units;
        this.points_earned = obj?.points_earned

    }
}
