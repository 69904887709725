import { EMPTY } from 'rxjs';
import { createActionGroup, props } from '@ngrx/store';
import { RankingModel } from '../../_models/ranking.model';

export const RankingActions = createActionGroup({
    source: 'ranking',
    events: {
        'Load Ranking': props<{ filters: {} }>(),
        'Load Ranking Success': props<{
            items: ReadonlyArray<RankingModel>;
            total: number;
        }>(),
    },
});