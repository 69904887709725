import { createSelector, createFeatureSelector } from '@ngrx/store';

import { PrizeModel } from '../../_models/prize.model';
import { SalesSumaryState } from '../_reducers/sales-sumary.reducer';
import { DataPerDayModel, SalesSumaryModel } from '../../_models/sales_sumary.model';

export const selectSalesSumaryState = createFeatureSelector<SalesSumaryState>('salesSumary');


export const currentSalesSumary = createSelector(
    selectSalesSumaryState,
    (salesSumary: SalesSumaryState) => new SalesSumaryModel(salesSumary.list?.data)
);


export const currentSalesSumaryFilters = createSelector(
    selectSalesSumaryState,
    (salesSumary: SalesSumaryState) => salesSumary.list.filters
);

export const currentSalesSumaryPerDayList = createSelector(
    selectSalesSumaryState,
    (salesSumary: SalesSumaryState) => new DataPerDayModel(salesSumary.listPerDay.data)
);

export const currentSalesSumaryPerDaytotal = createSelector(
    selectSalesSumaryState,
    (salesSumary: SalesSumaryState) => salesSumary.listPerDay.total
);


export const currentSalesSumaryPerDayFilters = createSelector(
    selectSalesSumaryState,
    (salesSumary: SalesSumaryState) => salesSumary.listPerDay.filters
);
