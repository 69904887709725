import { createSelector, createFeatureSelector } from '@ngrx/store';
import { RankingState } from '../_reducers/ranking.reducer';
import { RankingModel } from '../../_models/ranking.model';

export const selectRankingState = createFeatureSelector<RankingState>('ranking');

export const currentRankingList = createSelector(
    selectRankingState,
    (ranking: RankingState) =>
        ranking.list.data.map((ranking) => new RankingModel(ranking))
);

export const currentRankingTotal = createSelector(
    selectRankingState,
    (ranking: RankingState) => ranking.list.total
);

export const currentRankingFilters = createSelector(
    selectRankingState,
    (ranking: RankingState) => ranking.list.filters
);