export class SalesSumaryModel {
    total_units: number;
    total_price: number;
    total_commission: number;
    points_earned: number;
    summaryPerDay: SumaryPerDayModel;

    constructor(obj: any = null) {
        this.total_commission = obj?.total_commission;
        this.total_price = obj?.total_price;
        this.total_units = obj?.total_units;
        this.points_earned = obj?.points_earned
        this.summaryPerDay = new SumaryPerDayModel(obj?.summaryPerDay);
    }
}

export class SumaryPerDayModel {
    data: DataPerDayModel[]
    total: number

    constructor(obj: any = null) {
        this.data = obj?.data.map((a: any) => new DataPerDayModel(a)) || [];
        this.total = obj?.total;
    }
}

export class DataPerDayModel {
    date: string
    total_units: number;
    total_price: number;
    total_commission: number;
    points_earned: number;
    constructor(obj: any = null) {
        this.date = obj?.date
        this.total_commission = obj?.total_commission;
        this.total_price = obj?.total_price;
        this.total_units = obj?.total_units;
        this.points_earned = obj?.points_earned
    }
}