import { EMPTY } from 'rxjs';
import { createActionGroup, props } from '@ngrx/store';
import { SalesByServiceModel } from '../../_models/sales_by_service.model';

export const SalesByServiceActions = createActionGroup({
    source: 'salesByService',
    events: {
        'Load sales by service': props<{ filters: {} }>(),
        'Load sales by service Success': props<{ data: ReadonlyArray<SalesByServiceModel> }>(),
    },
});