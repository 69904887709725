
// Angular
import { Injectable } from '@angular/core';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';



@Injectable({
   providedIn: 'root'
})
export class HttpUtilsService {
   /**
    * Prepare query http params
    * @param queryParams: QueryParamsModel
    */
   getFindHTTPParams(queryParams: any): HttpParams {
      //  return new HttpParams()
      //    // .set('lastNamefilter', queryParams.filter)
      //    .set('sortOrder', queryParams.sortOrder)
      //    .set('sortField', queryParams.sortField)
      //    .set('pageNumber', queryParams.pageNumber.toString())
      //    .set('pageSize', queryParams.pageSize.toString());


      let params = new HttpParams()

      if (queryParams?.paginator) {

         let page = (queryParams.paginator.page - 1);
         let pageSize = queryParams.paginator.pageSize;
         let offset = page * pageSize;

         params = params.set('page', (queryParams.paginator.page).toString())
         params = params.set('offset', offset.toString())
         params = params.set('limit', queryParams.paginator.pageSize.toString());
      }

      if (queryParams?.sorting?.column && queryParams?.sorting?.column != 'id') {
         params = params.set('sort', queryParams.sorting.column + '.' + queryParams.sorting.direction)
      }


      if (queryParams.filter) {
         const keys = Object.keys(queryParams.filter)
         keys.map(key => {
            params = params.set(key, queryParams.filter[key])
         })
      }

      return params;
   }

   /**
    * get standard content-type
    */
   getHTTPHeaders(): HttpHeaders {
      let result = new HttpHeaders();
      let authToken = localStorage.getItem('token')
      // const user = JSON.parse(
      //    localStorage.getItem(`${environment.appVersion}-${environment.USERDATA_KEY}`)
      // );

      // if (user) {
      //    authToken = user.authToken;
      // }

      result = result.set('Content-Type', 'application/json')
      result = result.set('Authorization', 'Bearer ' + authToken)
      return result;
   }


   // baseFilter(_entities: any[], _queryParams: QueryParamsModel, _filtrationFields: string[] = []): QueryResultsModel {
   //    const httpExtention = new HttpExtenstionsModel();
   //    return httpExtention.baseFilter(_entities, _queryParams, _filtrationFields);
   // }

   // sortArray(_incomingArray: any[], _sortField: string = '', _sortOrder: string = 'asc'): any[] {
   //    const httpExtention = new HttpExtenstionsModel();
   //    return httpExtention.sortArray(_incomingArray, _sortField, _sortOrder);
   // }

   // searchInArray(_incomingArray: any[], _queryObj: any, _filtrationFields: string[] = []): any[] {
   //    const httpExtention = new HttpExtenstionsModel();
   //    return httpExtention.searchInArray(_incomingArray, _queryObj, _filtrationFields);
   // }
}
